<template>
  <div>
    <app-page-detail page-title="Edit BA Checklist ABD" page-toolbar-title="Edit BA Checklist ABD" :record-not-found="notFound">
      <template #toolbar>
        <app-button @click="getDetail(true)" title="Refresh" mdi-icon="mdi-refresh" :loading="loading"></app-button>
        <!-- <app-button @click="printDocument()" title="Print" mdi-icon="mdi-printer"></app-button> -->
        <app-log-data-dialog module_name="baabdcheck" :id="baabd_check_header.id">
          <app-button title="Document Log" mdi-icon="mdi-clipboard-text-clock"></app-button>
        </app-log-data-dialog>
        <v-divider vertical class="mx-2"></v-divider>
        <app-button @click="redirect('Finance.BaabdCheck.Detail', { id: id })" title="Detail" mdi-icon="mdi-file-find"></app-button>
        <app-button @click="redirect('Finance.BaabdCheck.PrintPreview', { id: baabd_check_header.id })" title="Print Preview" mdi-icon="mdi-printer"></app-button>
        <app-button :loading="loading_save" v-if="baabd_check_header.status == 'open'" @click="saveData()" mdi-icon="mdi-content-save" title="Save"></app-button>
      </template>
      <v-row>
        <v-col cols="12" lg="9">
          <v-row>
            <v-col cols="12" md="12">
              <card-expansion title="General" class="mb-3" :loading="loading">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field dense disabled readonly v-model="baabd_check_header.document_no_" placeholder="No." label="No." outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense disabled readonly v-model="baabd_check_header.document_date" type="date" placeholder="Doc. Date" label="Doc. Date" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field dense disabled readonly v-model="baabd_check_header.area_code" placeholder="Area Code" label="Area Code" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense disabled readonly v-model="baabd_check_header.status" placeholder="Status" label="Status" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>

                <v-divider class="mb-2"></v-divider>

                <v-row>
                  <v-col cols="6" md="6">
                    <v-text-field dense v-model="baabd_check_header.customer_no_" placeholder="Customer No." label="Customer No." outlined hide-details class="mb-2" append-icon="mdi-dots-horizontal" @click:append="tsearch.dialog = true" readonly></v-text-field>
                    <v-text-field dense v-model="baabd_check_header.customer_name" placeholder="Customer Name" label="Customer Name" outlined hide-details class="mb-2" readonly></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field dense v-model="baabd_check_header.campaign_no_" placeholder="Campaign No." label="Campaign No." outlined hide-details class="mb-2" append-icon="mdi-dots-horizontal" @click:append="tsearch2.dialog = true" readonly></v-text-field>
                    <v-text-field readonly dense v-model="baabd_check_header.campaign_description" placeholder="Campaign Description" label="Campaign Description" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>

                <v-divider class="mb-2"></v-divider>

                <v-row>
                  <v-col cols="6" md="6">
                    <app-text-field dense v-model="baabd_check_header.checklist_date" placeholder="Check List Date" label="Check List Date" type="date" outlined hide-details class="mb-2" clearable></app-text-field>
                  </v-col>
                </v-row>
              </card-expansion>

              <card-expansion title="Detail" class="mb-6" :loading="loading" no-padding>
                <template slot="action">
                  <v-btn color="primary" small elevation="0" :loading="loading_generate" @click="generateDetail()">Generate</v-btn>
                  <v-divider vertical class="mx-2"></v-divider>
                  <v-btn @click="showDialogAddDetail()" color="primary" icon>
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-divider vertical class="mx-2"></v-divider>
                </template>
                <v-row>
                  <v-col>
                    <div class="mx-4 mb-2">
                      <div style="max-width: 300px">
                        <v-text-field v-model="datatable_detail.search" label="Search" outlined dense hide-details></v-text-field>
                      </div>
                    </div>
                    <v-data-table :items="baabd_check_detail" :headers="datatable_detail.headers" :loading="datatable_detail.loading" class="elevation-0" :search="datatable_detail.search" dense :sort-by="datatable_detail.sortBy" :sort-desc="datatable_detail.sortDesc" fixed-header height="360px">
                      <template v-slot:[`item.action`]="{ item }">
                        <v-btn @click="deleteDetail(item)" class="mr-2" icon small color="red">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>

                      <template v-slot:[`item.po_contract_no_`]="{ item }">
                        <v-edit-dialog :return-value.sync="item.po_contract_no_" large persistent>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <span v-if="item.line_type == 'PO/KONTRAK'" class="font-weight-bold">{{ item.po_contract_no_ }}</span>
                                <span v-else class="text--disabled">{{ item.po_contract_no_ }}</span>
                              </div>
                            </template>
                            <span>Click to Edit</span>
                          </v-tooltip>

                          <template v-slot:input>
                            <div class="mt-4 text-h6">Update PO/Kontrak</div>
                            <v-text-field v-model="item.po_contract_no_" label="Edit" single-line counter autofocus></v-text-field>
                          </template>
                        </v-edit-dialog>
                        <!-- <v-text-field type="text" v-model="item.po_contract_no_" hide-details outlined dense></v-text-field> -->
                      </template>
                      <template v-slot:[`item.copy_invoice`]="{ item }">
                        <v-edit-dialog :return-value.sync="item.copy_invoice" large persistent>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <span v-if="item.line_type == 'INVOICE'" class="font-weight-bold">{{ item.copy_invoice }}</span>
                                <span v-else class="text--disabled">{{ item.copy_invoice }}</span>
                              </div>
                            </template>
                            <span>Click to Edit</span>
                          </v-tooltip>
                          <template v-slot:input>
                            <div class="mt-4 text-h6">Copy Invoice</div>
                            <v-text-field v-model="item.copy_invoice" label="Edit" single-line counter autofocus></v-text-field>
                          </template>
                        </v-edit-dialog>
                        <!-- <v-text-field type="text" v-model="item.copy_invoice" hide-details outlined dense></v-text-field> -->
                      </template>
                      <template v-slot:[`item.invoice_receipt`]="{ item }">
                        <v-edit-dialog :return-value.sync="item.invoice_receipt" large persistent>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <span v-if="item.line_type == 'TANDA TERIMA'" class="font-weight-bold">{{ item.invoice_receipt }}</span>
                                <span v-else class="text--disabled">{{ item.invoice_receipt }}</span>
                              </div>
                            </template>
                            <span>Click to Edit</span>
                          </v-tooltip>
                          <template v-slot:input>
                            <div class="mt-4 text-h6">Tanda Terima</div>
                            <v-text-field v-model="item.invoice_receipt" label="Edit" single-line counter autofocus></v-text-field>
                          </template>
                        </v-edit-dialog>
                        <!-- <v-text-field type="text" v-model="item.invoice_receipt" hide-details outlined dense></v-text-field> -->
                      </template>
                      <template v-slot:[`item.remark`]="{ item }">
                        <v-edit-dialog :return-value.sync="item.remark" large persistent>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">{{ item.remark ? item.remark : "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" }}</div>
                            </template>
                            <span>Click to Edit</span>
                          </v-tooltip>
                          <template v-slot:input>
                            <div class="mt-4 text-h6">Keterangan</div>
                            <v-text-field v-model="item.remark" label="Edit" single-line counter autofocus></v-text-field>
                          </template>
                        </v-edit-dialog>
                        <!-- <v-text-field type="text" v-model="item.remark" hide-details outlined dense></v-text-field> -->
                      </template>
                      <template v-slot:[`item.file_exist`]="{ item }">
                        <div class="mx-auto d-block text-center">
                          <v-checkbox v-model="item.file_exist" value hide-details class="my-0 py-0" :label="item.file_exist ? 'Ada' : 'Tidak Ada'"></v-checkbox>
                        </div>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </card-expansion>

              <card-expansion title="Rekonsiliasi Hutang Piutang" class="mb-6" :loading="loading">
                <v-row>
                  <v-col cols="12" md="3">
                    <label class="d-block my-1 py-1">Berita Acara</label>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-checkbox v-model="baabd_check_header.berita_acara_check" value hide-details class="my-1 py-1" :label="baabd_check_header.berita_acara_check ? 'Ada' : 'Tidak Ada'"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field label="Keterangan Berita Acara" v-model="baabd_check_header.berita_acara_remark" hide-details outlined dense></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label class="d-block my-1 py-1">MOM</label>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-checkbox v-model="baabd_check_header.mom_check" value hide-details class="my-1 py-1" :label="baabd_check_header.mom_check ? 'Ada' : 'Tidak Ada'"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field label="Keterangan MOM" v-model="baabd_check_header.mom_remark" hide-details outlined dense></v-text-field>
                  </v-col>
                </v-row>
              </card-expansion>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="3">
          <document-info :baabd_check_header="baabd_check_header"></document-info>
          <app-comment :document_id="parseInt(baabd_check_header.id)" :source_document="baabd_check_header.source_document" :document_no_="baabd_check_header.document_no_"></app-comment>
        </v-col>
      </v-row>
    </app-page-detail>
    <app-dialog v-model="dialogAddDetail" title="Add Detail" width="600px">
      <v-card>
        <v-card-text>
          <!-- <v-select :items="lineTypes" label="Tipe" placeholder="Tipe" outlined hide-details class="mb-2" dense v-model="dialogAddDetailData.line_type"></v-select> -->
          <v-radio-group v-model="dialogAddDetailData.line_type" row>
            <v-radio v-for="(t, i) in lineTypes" :label="t" :value="t" :key="i"></v-radio>
          </v-radio-group>
          <v-text-field v-if="inArray(dialogAddDetailData.line_type, ['PO/KONTRAK', 'INVOICE', 'TANDA TERIMA'])" label="PO/Kontrak" placeholder="PO/Kontrak" outlined hide-details class="mb-2" dense v-model="dialogAddDetailData.po_contract_no_"></v-text-field>
          <v-text-field v-if="inArray(dialogAddDetailData.line_type, ['INVOICE', 'TANDA TERIMA'])" label="Copy Invoice" placeholder="Copy Invoice" outlined hide-details class="mb-2" dense v-model="dialogAddDetailData.copy_invoice"></v-text-field>
          <v-text-field v-if="inArray(dialogAddDetailData.line_type, ['TANDA TERIMA'])" label="Tanda Terima" placeholder="Tanda Terima" outlined hide-details class="mb-2" dense v-model="dialogAddDetailData.invoice_receipt"></v-text-field>
        </v-card-text>
      </v-card>

      <template slot="actions">
        <v-btn @click="addDetail()" color="primary">Add</v-btn>
      </template>
    </app-dialog>
    <app-dialog v-model="dialogEditDetail" title="Edit Detail" width="600px">
      <v-card>
        <v-card-text>
          <input v-model="dialogEditDetail.id" />
          <v-text-field type="date" label="Tanggal" placeholder="Tanggal" outlined hide-details class="mb-2" dense v-model="dialogEditDetailData.line_date"></v-text-field>
          <v-textarea label="Deskripsi" placeholder="Deskripsi" outlined hide-details class="mb-2" dense v-model="dialogEditDetailData.line_description"></v-textarea>
        </v-card-text>
      </v-card>

      <template slot="actions">
        <v-btn @click="updateDetail()" color="primary">Update</v-btn>
      </template>
    </app-dialog>
    <app-table-search-dialog v-model="tsearch.dialog" title="Search Customer" :uri="tsearch.uri" :headers="tsearch.headers" @row-selected="handleRowSelectedCustomer"></app-table-search-dialog>
    <app-table-search-dialog v-model="tsearch2.dialog" title="Search Campaign" :uri="tsearch2.uri" :headers="tsearch2.headers" @row-selected="handleRowSelectedCampaign"></app-table-search-dialog>
  </div>
</template>

<script>
// import ApprovalList from "./components/ApprovalList.vue";
import DocumentInfo from "./components/DocumentInfo.vue";
// import ActionButton from './components/ActionButton.vue';
// import SetCloseDocument from "./components/SetCloseDocument.vue";

import AppTextField from "@/components/AppTextField.vue";

export default {
  components: {
    // CommentList,
    // ApprovalList,
    DocumentInfo,
    // ActionButton,
    // SetCloseDocument,
    AppTextField,
  },
  data() {
    return {
      id: null,
      loading: false,
      notFound: false,
      data_detail: {},
      baabd_check_header: {},
      baabd_check_detail: [],
      baabd_check_approval: [],
      loading_save: false,
      campaign_list: [],
      search_campaign: "",
      loading_search_campaign: false,
      selected_campaign: {},

      customer_list: [],
      search_customer: "",
      loading_search_customer: false,
      selected_customer: {},

      datatable_detail: {
        search: "",
        loading: false,
        sortBy: ["po_contract_no_", "copy_invoice", "invoice_receipt"],
        sortDesc: [false, false, false],
        headers: [
          { sortable: false, text: "#", value: "action" },
          // { tsortable: false, ext: "Type", value: "line_type" },
          { sortable: false, text: "PO/Kontrak", value: "po_contract_no_", class: "col-md-3" },
          { sortable: false, text: "Copy Invoice", value: "copy_invoice", class: "col-md-2" },
          { sortable: false, text: "Tanda Terima", value: "invoice_receipt", class: "col-md-2" },
          { sortable: false, text: "Kelengkapan", value: "file_exist", class: "col-md-2" },
          { sortable: false, text: "Keterangan", value: "remark", class: "col-md-3" },
        ],
      },
      dialogAddDetail: false,
      dialogAddDetailData: {
        id: "",
        line_type: "",
        po_contract_no_: "",
        copy_invoice: "",
        invoice_receipt: "",
      },
      dialogEditDetail: false,
      dialogEditDetailData: {
        id: "",
        line_type: "",
        po_contract_no_: "",
        copy_invoice: "",
        invoice_receipt: "",
      },
      dialogEditSelectedIndex: null,

      lineTypes: ["PO/KONTRAK", "INVOICE", "TANDA TERIMA"],

      loading_generate: false,

      tsearch: {
        uri: "customer/data",
        dialog: false,
        headers: [
          { text: "No.", value: "no_", class: "text-no-wrap" },
          { text: "Name", value: "name", class: "text-no-wrap" },
          { text: "Address", value: "address", class: "text-no-wrap" },
        ],
      },

      tsearch2: {
        uri: "campaign/data",
        dialog: false,
        headers: [
          { text: "No.", value: "no_", class: "text-no-wrap" },
          { text: "Description", value: "description", class: "text-no-wrap" },
          { text: "Status", value: "status_code", class: "text-no-wrap" },
        ],
      },
    };
  },
  watch: {
    search_campaign(n) {
      if (n) {
        if (n.length > 0) {
          this.getCampaign(n);
        }
      }
    },

    selected_campaign(n) {
      if (n.no_) {
        this.baabd_check_header.campaign_no_ = n.no_;
        this.baabd_check_header.campaign_description = n.description;
      }
    },

    search_customer(n) {
      if (n) {
        if (n.length > 0) {
          this.getCustomer(n);
        }
      }
    },

    selected_customer(n) {
      console.log(n);

      if (typeof n.no_ !== "undefined") {
        this.baabd_check_header.customer_no_ = n.no_;
        this.baabd_check_header.customer_name = n.name;
      }
    },
  },
  computed: {
    dtId() {
      return parseInt(this.$route.query.id);
    },
  },
  methods: {
    refreshDetail() {
      this.getDetail(true);
    },

    /**
     * getDetail
     * Method untuk mengambil data detail dokumen
     */
    async getDetail(refresh = false) {
      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      if (refresh == true) this.AxiosStorageRemove("GET", "baabdcheck/detail");

      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.dtId,
        },
        cacheConfig: !refresh,
      };
      await this.$axios
        .get("baabdcheck/detail", config)
        .then((res) => {
          this.data_detail = res.data.data;
          this.baabd_check_header = res.data.data.baabd_check_header;
          this.baabd_check_detail = res.data.data.baabd_check_detail;
          this.baabd_check_approval = res.data.data.baabd_check_approval;

          this.selected_customer.no_ = this.baabd_check_header.customer_no_;
          this.selected_customer.name = this.baabd_check_header.customer_name;
          this.selected_customer.text = this.baabd_check_header.customer_no_ + "-" + this.baabd_check_header.customer_name;

          this.customer_list.push(this.selected_customer);

          this.selected_campaign.no_ = this.baabd_check_header.campaign_no_;
          this.selected_campaign.description = this.baabd_check_header.campaign_description;
          this.selected_campaign.text = this.baabd_check_header.campaign_no_ + "-" + this.baabd_check_header.campaign_description;

          this.campaign_list.push(this.selected_campaign);

          this.showLoadingOverlay(false);

          this.loading = false;
          this.notFound = false;
        })
        .catch((error) => {
          if (typeof error.response != "undefined") {
            if (error.response.status == 404) {
              this.notFound = true;
            }
          }
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async generateDetail() {
      if (this.baabd_check_detail.length > 0) {
        if (!confirm("Apakah anda yakin ingin generate detail? \nData di line detail akan di replace!")) return;
      }
      this.loading_generate = true;
      this.showLoadingOverlay(true);
      var data = {
        id: this.baabd_check_header.id,
        customer_no_: this.baabd_check_header.customer_no_,
        campaign_no_: this.baabd_check_header.campaign_no_,
      };

      await this.$axios
        .post("baabdcheck/generate-detail", this.objectToFormData(data))
        .then((res) => {
          this.loading_generate = false;
          this.showLoadingOverlay(false);

          var resData = res.data;

          this.baabd_check_detail = resData.data;

          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
          }
          // this.refreshDetail();
        })
        .catch((error) => {
          this.loading_generate = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
          this.refreshDetail();
        });
    },

    async saveData() {
      this.loading_save = true;
      this.showLoadingOverlay(true);
      var data = {
        baabd_check_header: this.baabd_check_header,
        baabd_check_detail: this.baabd_check_detail,
      };

      await this.$axios
        .post("baabdcheck/save", this.objectToFormData(data))
        .then((res) => {
          this.loading_save = false;
          this.showLoadingOverlay(false);

          var resData = res.data;

          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
          }
          this.refreshDetail();
        })
        .catch((error) => {
          this.loading_save = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
          this.refreshDetail();
        });
    },

    async getCampaign(search = "") {
      this.loading_search_campaign = true;
      await this.$axios
        .get("baabdcheck/get-campaign", {
          params: {
            search: search,
            customer_no_: this.baabd_check_header.customer_no_,
          },
        })
        .then((res) => {
          this.campaign_list = res.data.data.results;
          this.loading_search_campaign = false;
        })
        .catch((error) => {
          this.loading_search_campaign = false;
          this.axiosErrorHandler(error);
        });
    },

    async getCustomer(search = "") {
      this.loading_search_customer = true;
      await this.$axios
        .get("baabdcheck/get-customer", {
          params: {
            search: search,
          },
        })
        .then((res) => {
          this.customer_list = res.data.data.results;
          this.loading_search_customer = false;
        })
        .catch((error) => {
          this.loading_search_customer = false;
          this.axiosErrorHandler(error);
        });
    },

    showDialogAddDetail() {
      this.dialogAddDetailData = {};
      this.dialogAddDetail = true;
    },

    addDetail() {
      let rowToAdd = {
        id: "",
        line_type: "",
        po_contract_no_: "",
        copy_invoice: "",
        invoice_receipt: "",
      };

      // console.log("this.dialogAddDetailData", this.dialogAddDetailData);
      // return;

      // validasi
      if (typeof this.dialogAddDetailData.line_type == "undefined" || this.dialogAddDetailData.line_type == "" || this.dialogAddDetailData.line_type == null) {
        this.showAlert("error", "Tipe harus diisi");
        return;
      }

      rowToAdd.line_type = this.dialogAddDetailData.line_type;

      if (rowToAdd.line_type == "PO/KONTRAK") {
        // validasi
        if (typeof this.dialogAddDetailData.po_contract_no_ == "undefined" || this.dialogAddDetailData.po_contract_no_ == "" || this.dialogAddDetailData.po_contract_no_ == null) {
          this.showAlert("error", "PO/Kontrak harus diisi");
          return;
        }

        rowToAdd.po_contract_no_ = this.dialogAddDetailData.po_contract_no_;
      } else if (rowToAdd.line_type == "INVOICE") {
        // validasi
        if (typeof this.dialogAddDetailData.po_contract_no_ == "undefined" || this.dialogAddDetailData.po_contract_no_ == "" || this.dialogAddDetailData.po_contract_no_ == null) {
          this.showAlert("error", "PO/Kontrak harus diisi");
          return;
        }
        if (typeof this.dialogAddDetailData.copy_invoice == "undefined" || this.dialogAddDetailData.copy_invoice == "" || this.dialogAddDetailData.copy_invoice == null) {
          this.showAlert("error", "Copy Invoice harus diisi");
          return;
        }

        rowToAdd.po_contract_no_ = this.dialogAddDetailData.po_contract_no_;
        rowToAdd.copy_invoice = this.dialogAddDetailData.copy_invoice;
      } else if (rowToAdd.line_type == "TANDA TERIMA") {
        // validasi
        if (typeof this.dialogAddDetailData.po_contract_no_ == "undefined" || this.dialogAddDetailData.po_contract_no_ == "" || this.dialogAddDetailData.po_contract_no_ == null) {
          this.showAlert("error", "PO/Kontrak harus diisi");
          return;
        }
        if (typeof this.dialogAddDetailData.copy_invoice == "undefined" || this.dialogAddDetailData.copy_invoice == "" || this.dialogAddDetailData.copy_invoice == null) {
          this.showAlert("error", "Copy Invoice harus diisi");
          return;
        }
        if (typeof this.dialogAddDetailData.invoice_receipt == "undefined" || this.dialogAddDetailData.invoice_receipt == "" || this.dialogAddDetailData.invoice_receipt == null) {
          this.showAlert("error", "Tanda Terima harus diisi");
          return;
        }

        rowToAdd.po_contract_no_ = this.dialogAddDetailData.po_contract_no_;
        rowToAdd.copy_invoice = this.dialogAddDetailData.copy_invoice;
        rowToAdd.invoice_receipt = this.dialogAddDetailData.invoice_receipt;
      }

      this.baabd_check_detail.push(rowToAdd);

      this.dialogAddDetailData = {};
      this.dialogAddDetail = false;
    },

    deleteDetail(item) {
      var index = this.baabd_check_detail.indexOf(item);

      if (item.line_type == "PO/KONTRAK") {
        this.baabd_check_detail.splice(index, 1);
        for (var i = 0; i < this.baabd_check_detail.length; i++) {
          if (this.baabd_check_detail[i].po_contract_no_ == item.po_contract_no_) {
            this.baabd_check_detail.splice(i, 1);
          }
        }

        // this.baabd_check_detail.splice(index, 1);
        return;
      }

      if (item.line_type == "INVOICE") {
        for (var ii = 0; ii < this.baabd_check_detail.length; ii++) {
          if (this.baabd_check_detail[ii].copy_invoice == item.copy_invoice) {
            this.baabd_check_detail.splice(ii, 1);
          }
        }
        // this.baabd_check_detail.splice(index, 1);
        return;
      }

      if (item.line_type == "TANDA TERIMA") {
        this.baabd_check_detail.splice(index, 1);
        return;
      }
    },

    showDialogEditDetail(item) {
      this.dialogEditSelectedIndex = this.baabd_check_detail.indexOf(item);
      this.dialogEditDetail = true;
      const cloneItem = Object.assign({}, item);
      this.dialogEditDetailData = cloneItem;
    },

    updateDetail() {
      var index = this.dialogEditSelectedIndex;
      this.baabd_check_detail[index].id = this.dialogEditDetailData.id;
      this.baabd_check_detail[index].line_date = this.dialogEditDetailData.line_date;
      this.baabd_check_detail[index].line_description = this.dialogEditDetailData.line_description;
      this.dialogEditDetailData = {};
      this.dialogEditDetail = false;
    },

    handleRowSelectedCustomer(item) {
      this.tsearch.dialog = false;
      this.baabd_check_header.customer_no_ = item.no_;
      this.baabd_check_header.customer_name = item.name;
    },

    handleRowSelectedCampaign(item) {
      this.tsearch2.dialog = false;
      this.baabd_check_header.campaign_no_ = item.no_;
      this.baabd_check_header.campaign_description = item.description;
    },
  },

  beforeMount() {
    if (typeof this.$route.query.id !== "undefined") {
      this.id = parseInt(this.$route.query.id);
    } else {
      this.id = parseInt(this.$route.params.id);
    }
  },

  mounted() {
    this.getDetail(true);
  },
};
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  vertical-align: top;
  padding-top: 5px;
}

.custom-form-label {
  font-size: 16px;
}
.custom-form-label.dense {
  font-size: 16px !important;
  line-height: 1.375rem;
  /* buat text ada di verical tengah */
  display: flex;
  align-items: center;
}
</style>
